import { MessageBarType } from '@fluentui/react'
import { createReducer } from 'typesafe-actions'
import {
  IClientSearchState,
  ISPAGetResponce,
  ISecuritySearchState,
  IAccountSearchState,
  IGeneratePDFSPAApiRes,
  ICustodianName,
  IValidationMessage,
  ISPAError,
  IExistedAccountNumberApiRes,
  IErrorSearchState,
  IGetAdvisorSecurityByCusipApiRes,
  IGetAdvisorAccountsByClientIdApiRes,
  IFundAdmin,
  IAccountRequests
} from '../api/types'
import {
  IErrorSummaryState,
  initialState as SPAErrorSummaryInitialState
} from '../features/Admin/store/errorSummary'
import {
  SPAActionsTypes,
  accountSearchActions,
  clientSearchActions,
  downloadSPADocActions,
  fetchExistingSPAActions,
  fetchSPAAttachmentsActions,
  insertSPAAttachmentsActions,
  insertUpdateSPAActions,
  securitySearchActions,
  updateSPAStatusActions,
  deleteSPADocActions,
  generatePDFSPAActions,
  generateAccountNumberActions,
  custodianNameActions,
  validateSPAActions,
  errorSPAActions,
  exportSPAToExcelActions,
  exportSPAErrorToExcelActions,
  fetchCusipSequenceNumberActions,
  fetchAdvisorSecurityByCusipActions,
  errorSearchActions,
  fetchAdvisorAccountsByClientIdActions,
  fetchFundAdminsActions,
  fetchCRMAccountsActions
} from './actions'

export interface ISPAState {
  insertUpdateSPAState?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    outputResponse?: string
    data?: any
  }
  fetchExistingSPAState?: {
    inProgress: boolean
    error?: Error
    data?: ISPAGetResponce
  }
  clientSearchState?: IClientSearchState
  securitySearchState?: ISecuritySearchState
  updateSPAState?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    outputResponse?: string
    data?: any
  }
  insertAttachmentsSPAState?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    data?: any
  }
  accountSearchState?: IAccountSearchState
  spaAttachmentsState?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    data?: string[]
  }
  SPADownload?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
  }
  SPADelete?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
  }
  generatePDFState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    msg?: string
    data?: IGeneratePDFSPAApiRes
  }
  fetchCustodianNameState?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    data?: ICustodianName[]
  }
  validateSPAState?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    data?: IValidationMessage[]
  }

  errorSearchState?: IErrorSearchState

  errorSPAState?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    data?: ISPAError[]
  }
  exportSPASummary?: {
    inProgress: boolean
    error?: Error
  }
  exportSPAError?: {
    inProgress: boolean
    error?: Error
  }
  generateAccountNumberState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    msg?: string
    data?: string
  }
  fetchCusipSequenceNumberState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    msg?: string
    data?: string
  }
  fetchAdvisorSecurityByCusipState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    msg?: string
    data?: IGetAdvisorSecurityByCusipApiRes
  }
  fetchAdvisorAccountsByClientIdState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    msg?: string
    data?: IGetAdvisorAccountsByClientIdApiRes
  }
  existedAccountNumberState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    msg?: string
    data?: IExistedAccountNumberApiRes
  }
  SPAErrorsSummary: IErrorSummaryState
  fetchFundAdminsState?: {
    inProgress: boolean
    error?: Error
    messageType?: MessageBarType
    msg?: string
    data?: IFundAdmin[]
  }
  fetchCRMAccountsState?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    data?: IAccountRequests[]
  }
}

const initialState: ISPAState = {
  SPAErrorsSummary: SPAErrorSummaryInitialState
}

export const advisorReducer = createReducer<ISPAState, SPAActionsTypes>(
  initialState
)
  .handleAction(insertUpdateSPAActions.request, (state) => ({
    ...state,
    insertUpdateSPAState: {
      inProgress: true,
      error: undefined,
      msg: undefined,
      messageType: undefined
    }
  }))
  .handleAction(insertUpdateSPAActions.success, (state, action) => ({
    ...state,
    insertUpdateSPAState: {
      inProgress: false,
      error: undefined,
      msg: 'Successfully saved record.',
      messageType: MessageBarType.success,
      data: action.payload,
      outputResponse: action.payload?.outputResponse
    }
  }))
  .handleAction(insertUpdateSPAActions.failure, (state, action) => ({
    ...state,
    insertUpdateSPAState: {
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(insertUpdateSPAActions.clear, (state) => ({
    ...state,
    insertUpdateSPAState: undefined
  }))
  .handleAction(clientSearchActions.request, (state) => ({
    ...state,
    clientSearchState: {
      ...state.clientSearchState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(clientSearchActions.success, (state, action) => ({
    ...state,
    clientSearchState: {
      inProgress: false,
      clientSearchList: action.payload,
      error: undefined
    }
  }))
  .handleAction(clientSearchActions.failure, (state, action) => ({
    ...state,
    clientSearchState: {
      inProgress: false,
      clientSearchList: undefined,
      error: action.payload
    }
  }))
  .handleAction(clientSearchActions.clear, (state) => ({
    ...state,
    clientSearchState: undefined
  }))
  .handleAction(fetchExistingSPAActions.request, (state) => ({
    ...state,
    fetchExistingSPAState: {
      inProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchExistingSPAActions.success, (state, action) => ({
    ...state,
    fetchExistingSPAState: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchExistingSPAActions.failure, (state, action) => ({
    ...state,
    fetchExistingSPAState: {
      inProgress: false,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchExistingSPAActions.clear, (state) => ({
    ...state,
    fetchExistingSPAState: undefined
  }))
  .handleAction(securitySearchActions.request, (state) => ({
    ...state,
    securitySearchState: {
      ...state.securitySearchState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(securitySearchActions.success, (state, action) => ({
    ...state,
    securitySearchState: {
      inProgress: false,
      securitySearchList: action.payload,
      error: undefined
    }
  }))
  .handleAction(securitySearchActions.failure, (state, action) => ({
    ...state,
    securitySearchState: {
      inProgress: false,
      securitySearchList: undefined,
      error: action.payload
    }
  }))
  .handleAction(securitySearchActions.clear, (state) => ({
    ...state,
    securitySearchState: undefined
  }))
  .handleAction(updateSPAStatusActions.request, (state) => ({
    ...state,
    updateSPAState: {
      inProgress: true,
      error: undefined,
      msg: undefined,
      messageType: undefined
    }
  }))
  .handleAction(updateSPAStatusActions.success, (state, action) => ({
    ...state,
    updateSPAState: {
      inProgress: false,
      error: undefined,
      msg: action.payload.msg,
      messageType: MessageBarType.success,
      data: action.payload,
      outputResponse: action.payload?.outputResponse
    }
  }))
  .handleAction(updateSPAStatusActions.failure, (state, action) => ({
    ...state,
    updateSPAState: {
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(updateSPAStatusActions.clear, (state) => ({
    ...state,
    updateSPAState: undefined
  }))
  .handleAction(insertSPAAttachmentsActions.request, (state) => ({
    ...state,
    insertAttachmentsSPAState: {
      inProgress: true,
      error: undefined,
      msg: undefined,
      messageType: undefined
    }
  }))
  .handleAction(insertSPAAttachmentsActions.success, (state, action) => ({
    ...state,
    insertAttachmentsSPAState: {
      inProgress: false,
      error: undefined,
      msg: 'Successfully uploaded attachment(s).',
      messageType: MessageBarType.success,
      data: action.payload
    }
  }))
  .handleAction(insertSPAAttachmentsActions.failure, (state, action) => ({
    ...state,
    insertAttachmentsSPAState: {
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(insertSPAAttachmentsActions.clear, (state) => ({
    ...state,
    insertAttachmentsSPAState: undefined
  }))
  .handleAction(accountSearchActions.request, (state) => ({
    ...state,
    accountSearchState: {
      ...state.accountSearchState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(accountSearchActions.success, (state, action) => ({
    ...state,
    accountSearchState: {
      inProgress: false,
      accountSearchList: action.payload,
      error: undefined
    }
  }))
  .handleAction(accountSearchActions.failure, (state, action) => ({
    ...state,
    accountSearchState: {
      inProgress: false,
      advisorSearchList: undefined,
      error: action.payload
    }
  }))
  .handleAction(accountSearchActions.clear, (state) => ({
    ...state,
    accountSearchState: undefined
  }))
  .handleAction(fetchSPAAttachmentsActions.request, (state) => ({
    ...state,
    spaAttachmentsState: {
      inProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchSPAAttachmentsActions.success, (state, action) => ({
    ...state,
    spaAttachmentsState: {
      inProgress: false,
      error: undefined,
      data: action.payload,
      messageType: MessageBarType.success
    }
  }))
  .handleAction(fetchSPAAttachmentsActions.failure, (state, action) => ({
    ...state,
    spaAttachmentsState: {
      inProgress: false,
      error: action.payload,
      data: undefined,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(fetchSPAAttachmentsActions.clear, (state) => ({
    ...state,
    spaAttachmentsState: undefined
  }))
  .handleAction(downloadSPADocActions.request, (state) => ({
    ...state,
    SPADownload: {
      ...state.SPADownload,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(downloadSPADocActions.success, (state) => ({
    ...state,
    SPADownload: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success
    }
  }))
  .handleAction(downloadSPADocActions.failure, (state, action) => ({
    ...state,
    SPADownload: {
      ...state.SPADownload,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(deleteSPADocActions.request, (state) => ({
    ...state,
    SPADelete: {
      ...state.SPADelete,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(deleteSPADocActions.success, (state) => ({
    ...state,
    SPADelete: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully deleted attachment'
    }
  }))
  .handleAction(deleteSPADocActions.failure, (state, action) => ({
    ...state,
    SPADelete: {
      ...state.SPADelete,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(deleteSPADocActions.clear, (state) => ({
    ...state,
    SPADelete: undefined
  }))
  .handleAction(generatePDFSPAActions.request, (state) => ({
    ...state,
    generatePDFState: {
      ...state.generatePDFState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info,
      msg: 'Generating the PDF'
    }
  }))
  .handleAction(generatePDFSPAActions.success, (state, action) => ({
    ...state,
    generatePDFState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully generated PDF',
      data: action.payload
    }
  }))
  .handleAction(generatePDFSPAActions.failure, (state, action) => ({
    ...state,
    generatePDFState: {
      ...state.generatePDFState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(generatePDFSPAActions.clear, (state) => ({
    ...state,
    generatePDFState: undefined
  }))
  .handleAction(custodianNameActions.request, (state) => ({
    ...state,
    fetchCustodianNameState: {
      ...state.fetchCustodianNameState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(custodianNameActions.success, (state, action) => ({
    ...state,
    fetchCustodianNameState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      data: action.payload
    }
  }))
  .handleAction(custodianNameActions.failure, (state, action) => ({
    ...state,
    fetchCustodianNameState: {
      ...state.fetchCustodianNameState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(custodianNameActions.clear, (state) => ({
    ...state,
    fetchCustodianNameState: undefined
  }))
  .handleAction(validateSPAActions.request, (state) => ({
    ...state,
    validateSPAState: {
      ...state.validateSPAState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(validateSPAActions.success, (state, action) => ({
    ...state,
    validateSPAState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      data: action.payload
    }
  }))
  .handleAction(validateSPAActions.failure, (state, action) => ({
    ...state,
    validateSPAState: {
      ...state.validateSPAState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(validateSPAActions.clear, (state) => ({
    ...state,
    validateSPAState: undefined
  }))
  .handleAction(errorSPAActions.request, (state) => ({
    ...state,
    errorSPAState: {
      ...state.errorSPAState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(errorSPAActions.success, (state, action) => ({
    ...state,
    errorSPAState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      data: action.payload
    }
  }))
  .handleAction(errorSPAActions.failure, (state, action) => ({
    ...state,
    errorSPAState: {
      ...state.errorSPAState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(errorSPAActions.clear, (state) => ({
    ...state,
    errorSPAState: undefined
  }))
  .handleAction(exportSPAToExcelActions.request, (state) => ({
    ...state,
    exportSPASummary: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(exportSPAToExcelActions.success, (state) => ({
    ...state,
    exportSPASummary: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(exportSPAToExcelActions.failure, (state, action) => ({
    ...state,
    exportSPASummary: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(exportSPAErrorToExcelActions.request, (state) => ({
    ...state,
    exportSPAError: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(exportSPAErrorToExcelActions.success, (state) => ({
    ...state,
    exportSPAError: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(exportSPAErrorToExcelActions.failure, (state, action) => ({
    ...state,
    exportSPAError: {
      inProgress: false,
      error: action.payload
    }
  }))
  .handleAction(generateAccountNumberActions.request, (state) => ({
    ...state,
    generateAccountNumberState: {
      ...state.generateAccountNumberState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info,
      msg: 'Generating the Account Number'
    }
  }))
  .handleAction(generateAccountNumberActions.success, (state, action) => ({
    ...state,
    generateAccountNumberState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully generated Account Number',
      data: action.payload
    }
  }))
  .handleAction(generateAccountNumberActions.failure, (state, action) => ({
    ...state,
    generateAccountNumberState: {
      ...state.generateAccountNumberState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(fetchCusipSequenceNumberActions.request, (state) => ({
    ...state,
    fetchCusipSequenceNumberState: {
      ...state.fetchCusipSequenceNumberState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info,
      msg: 'Get the Cusip Sequence Number'
    }
  }))
  .handleAction(fetchCusipSequenceNumberActions.success, (state, action) => ({
    ...state,
    fetchCusipSequenceNumberState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully fetched Cusip Sequence Number',
      data: action.payload
    }
  }))
  .handleAction(fetchCusipSequenceNumberActions.failure, (state, action) => ({
    ...state,
    fetchCusipSequenceNumberState: {
      ...state.fetchCusipSequenceNumberState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  //AdvisorSecurityByCusip
  .handleAction(fetchAdvisorSecurityByCusipActions.request, (state) => ({
    ...state,
    fetchAdvisorSecurityByCusipState: {
      ...state.fetchAdvisorSecurityByCusipState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info,
      msg: 'Get the Advisor Security by Cusip'
    }
  }))
  .handleAction(
    fetchAdvisorSecurityByCusipActions.success,
    (state, action) => ({
      ...state,
      fetchAdvisorSecurityByCusipState: {
        inProgress: false,
        error: undefined,
        messageType: MessageBarType.success,
        msg: 'Successfully fetched Advisor security by Cusip',
        data: action.payload
      }
    })
  )
  .handleAction(
    fetchAdvisorSecurityByCusipActions.failure,
    (state, action) => ({
      ...state,
      fetchAdvisorSecurityByCusipState: {
        ...state.fetchAdvisorSecurityByCusipState,
        inProgress: false,
        error: action.payload,
        msg: action.payload.message,
        messageType: MessageBarType.error
      }
    })
  )
  .handleAction(fetchAdvisorSecurityByCusipActions.clear, (state) => ({
    ...state,
    fetchAdvisorSecurityByCusipState: undefined
  }))
  //AdvisorAccountsByClientId
  .handleAction(fetchAdvisorAccountsByClientIdActions.request, (state) => ({
    ...state,
    fetchAdvisorAccountsByClientIdState: {
      ...state.fetchAdvisorAccountsByClientIdState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info,
      msg: 'Get the Advisor Security by Cusip'
    }
  }))
  .handleAction(
    fetchAdvisorAccountsByClientIdActions.success,
    (state, action) => ({
      ...state,
      fetchAdvisorAccountsByClientIdState: {
        inProgress: false,
        error: undefined,
        messageType: MessageBarType.success,
        msg: 'Successfully fetched Advisor security by Cusip',
        data: action.payload
      }
    })
  )
  .handleAction(
    fetchAdvisorAccountsByClientIdActions.failure,
    (state, action) => ({
      ...state,
      fetchAdvisorAccountsByClientIdState: {
        ...state.fetchAdvisorAccountsByClientIdState,
        inProgress: false,
        error: action.payload,
        msg: action.payload.message,
        messageType: MessageBarType.error
      }
    })
  )
  .handleAction(fetchAdvisorAccountsByClientIdActions.clear, (state) => ({
    ...state,
    fetchAdvisorAccountsByClientIdState: undefined
  }))
  .handleAction(errorSearchActions.request, (state) => ({
    ...state,
    errorSearchState: {
      ...state.errorSearchState,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(errorSearchActions.success, (state, action) => ({
    ...state,
    errorSearchState: {
      inProgress: false,
      errorSearchList: action.payload,
      error: undefined
    }
  }))
  .handleAction(errorSearchActions.failure, (state, action) => ({
    ...state,
    errorSearchState: {
      inProgress: false,
      errorSearchList: undefined,
      error: action.payload
    }
  }))
  .handleAction(errorSearchActions.clear, (state) => ({
    ...state,
    errorSearchState: undefined
  }))
  .handleAction(fetchFundAdminsActions.request, (state) => ({
    ...state,
    fetchFundAdminsState: {
      ...state.fetchFundAdminsState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info,
      msg: 'Get the Fund Admins'
    }
  }))
  .handleAction(fetchFundAdminsActions.success, (state, action) => ({
    ...state,
    fetchFundAdminsState: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully fetched Fund Admins',
      data: action.payload
    }
  }))
  .handleAction(fetchFundAdminsActions.failure, (state, action) => ({
    ...state,
    fetchFundAdminsState: {
      ...state.fetchFundAdminsState,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(fetchCRMAccountsActions.request, (state) => ({
    ...state,
    fetchCRMAccountsState: {
      ...state.fetchCRMAccountsState,
      inProgress: true,
      error: undefined,
      messageType: MessageBarType.info
    }
  }))
  .handleAction(fetchCRMAccountsActions.success, (state, action) => ({
    ...state,
    fetchCRMAccountsState: {
      inProgress: false,
      data: action.payload,
      error: undefined,
      messageType: MessageBarType.success
    }
  }))
  .handleAction(fetchCRMAccountsActions.failure, (state, action) => ({
    ...state,
    fetchCRMAccountsState: {
      inProgress: false,
      data: undefined,
      error: action.payload,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(fetchCRMAccountsActions.clear, (state) => ({
    ...state,
    fetchCRMAccountsState: undefined
  }))
