import { combineReducers, Reducer } from 'redux'
import { StateType } from 'typesafe-actions'
import { ocrModuleReducer, ocrModuleSagas } from './OCR'
import { ramModuleReducer, ramModuleSagas } from './Ram'
import { advisorModuleReducer, advisorModuleSagas } from './SPAAdvisor'
import {
  securityReferenceFetchReducer,
  securityReferenceFetchSagas
} from './SPAAdvisor/store/SecurityReferenceFetch'

export const modulesSagas = [
  ...advisorModuleSagas,
  ...securityReferenceFetchSagas,
  ...ramModuleSagas,
  ...ocrModuleSagas
]

interface IModulesState {
  SPA: ReturnType<typeof advisorModuleReducer>
  securityReferenceFetch: StateType<typeof securityReferenceFetchReducer>
  trust: any
  RAM: ReturnType<typeof ramModuleReducer>
  OCR: ReturnType<typeof ocrModuleReducer>
}

// export type DynamicModulesState =  //AdminModuleSlice // & TeamsModuleSlice
export type StaticModulesState = IModulesState

export const modulesReducer: Reducer<any> = combineReducers({
  SPA: advisorModuleReducer,
  securityReferenceFetch: securityReferenceFetchReducer,
  RAM: ramModuleReducer,
  OCR: ocrModuleReducer
})
