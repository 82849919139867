import { IAccount } from 'api/account.types'
import { IAdvisorDetailResult } from 'api/advisor.types'
import { ISearchResult } from 'api/common.types'
import { ISecurity } from 'api/security.types'
import { ActionType, createAction } from 'typesafe-actions'
import {
  IGetAdvisorRequest,
  ISPAGetResponce,
  IInsertUpdateSPAApiReq,
  IInsertUpdateSPAApiRes,
  IUpdateSPAStatusApiRes,
  IUpdateSPAStatusApiReq,
  ISecurityFilter,
  IGeneratePDFSPAApiRes,
  ICustodianName,
  IValidationMessage,
  ISPAError,
  ISPASummaryExportReq,
  ISPAErrorExportReq,
  IExistedAccountApiReq,
  IGetAdvisorSecurityByCusipApiRes,
  IGetAdvisorAccountsByClientIdApiRes,
  IFundAdmin,
  IAccountRequests,
  ICRMAccountExportReq
} from '../api/types'

export const INSERT_UPDATE_SPA = `@modules/@SPA/INSERT_UPDATE_SPA`
export const INSERT_UPDATE_SPA_SUCCESS = `@modules/@SPA/INSERT_UPDATE_SPA_SUCCESS`
export const INSERT_UPDATE_SPA_FAILURE = `@modules/@SPA/INSERT_UPDATE_SPA_FAILURE`
export const INSERT_UPDATE_SPA_CLEAR = `@modules/@SPA/INSERT_UPDATE_SPA_CLEAR`

export const FETCH_EXISTING_SPA_DETAILS = `@modules/@SPA/FETCH_EXISTING_SPA_DETAILS`
export const FETCH_EXISTING_SPA_DETAILS_SUCCESS = `@modules/@SPA/FETCH_EXISTING_SPA_DETAILS_SUCCESS`
export const FETCH_EXISTING_SPA_DETAILS_FAILURE = `@modules/@SPA/FETCH_EXISTING_SPA_DETAILS_FAILURE`
export const FETCH_EXISTING_SPA_DETAILS_CLEAR = `@modules/@SPA/FETCH_EXISTING_SPA_DETAILS_CLEAR`

export const fetchExistingSPAActions = {
  request: createAction(FETCH_EXISTING_SPA_DETAILS)<IGetAdvisorRequest>(),
  success: createAction(FETCH_EXISTING_SPA_DETAILS_SUCCESS)<ISPAGetResponce>(),
  failure: createAction(FETCH_EXISTING_SPA_DETAILS_FAILURE)<Error>(),
  clear: createAction(FETCH_EXISTING_SPA_DETAILS_CLEAR)<undefined>()
}

export const insertUpdateSPAActions = {
  request: createAction(INSERT_UPDATE_SPA)<IInsertUpdateSPAApiReq>(),
  success: createAction(INSERT_UPDATE_SPA_SUCCESS)<
    IInsertUpdateSPAApiRes | undefined
  >(),
  failure: createAction(INSERT_UPDATE_SPA_FAILURE)<Error>(),
  clear: createAction(INSERT_UPDATE_SPA_CLEAR)()
}

export const UPDATE_SPA_REQUEST = `@modules/@SPA/UPDATE_SPA_REQUEST`
export const UPDATE_SPA_SUCCESS = `@modules/@SPA/UPDATE_SPA_SUCCESS`
export const UPDATE_SPA_FAILURE = `@modules/@SPA/UPDATE_SPA_FAILURE`
export const UPDATE_SPA_CLEAR = `@modules/@SPA/UPDATE_SPA_CLEAR`

export const updateSPAStatusActions = {
  request: createAction(UPDATE_SPA_REQUEST)<IUpdateSPAStatusApiReq>(),
  success: createAction(UPDATE_SPA_SUCCESS)<IUpdateSPAStatusApiRes>(),
  failure: createAction(UPDATE_SPA_FAILURE)<Error>(),
  clear: createAction(UPDATE_SPA_CLEAR)()
}

export const CLIENT_SEARCH_REQUEST = `@modules/@SPA/CLIENT_SEARCH_REQUEST`
export const CLIENT_SEARCH_SUCCESS = `@modules/@SPA/CLIENT_SEARCH_SUCCESS`
export const CLIENT_SEARCH_FAILURE = `@modules/@SPA/CLIENT_SEARCH_FAILURE`
export const CLIENT_SEARCH_CLEAR = `@modules/@SPA/CLIENT_SEARCH_CLEAR`

export const clientSearchActions = {
  request: createAction(CLIENT_SEARCH_REQUEST)<string | undefined>(),
  success: createAction(CLIENT_SEARCH_SUCCESS)<ISearchResult<any>>(),
  failure: createAction(CLIENT_SEARCH_FAILURE)<Error>(),
  clear: createAction(CLIENT_SEARCH_CLEAR)()
}

export const ADVISOR_SEARCH_REQUEST = `@modules/@SPA/ADVISOR_SEARCH_REQUEST`
export const ADVISOR_SEARCH_SUCCESS = `@modules/@SPA/ADVISOR_SEARCH_SUCCESS`
export const ADVISOR_SEARCH_FAILURE = `@modules/@SPA/ADVISOR_SEARCH_FAILURE`
export const ADVISOR_SEARCH_CLEAR = `@modules/@SPA/ADVISOR_SEARCH_CLEAR`

export const advisorSearchActions = {
  request: createAction(ADVISOR_SEARCH_REQUEST)<string | undefined>(),
  success: createAction(ADVISOR_SEARCH_SUCCESS)<
    ISearchResult<IAdvisorDetailResult>
  >(),
  failure: createAction(ADVISOR_SEARCH_FAILURE)<Error>(),
  clear: createAction(ADVISOR_SEARCH_CLEAR)()
}

export const SECURITY_SEARCH_REQUEST = `@modules/@SPA/SECURITY_SEARCH_REQUEST`
export const SECURITY_SEARCH_SUCCESS = `@modules/@SPA/SECURITY_SEARCH_SUCCESS`
export const SECURITY_SEARCH_FAILURE = `@modules/@SPA/SECURITY_SEARCH_FAILURE`
export const SECURITY_SEARCH_CLEAR = `@modules/@SPA/SECURITY_SEARCH_CLEAR`

export const securitySearchActions = {
  request: createAction(SECURITY_SEARCH_REQUEST)<ISecurityFilter>(),
  success: createAction(SECURITY_SEARCH_SUCCESS)<ISearchResult<ISecurity>>(),
  failure: createAction(SECURITY_SEARCH_FAILURE)<Error>(),
  clear: createAction(SECURITY_SEARCH_CLEAR)()
}

export const ATTACHMENTS_SPA_REQUEST = `@modules/@SPA/ATTACHMENTS_SPA_REQUEST`
export const ATTACHMENTS_SPA_SUCCESS = `@modules/@SPA/ATTACHMENTS_SPA_SUCCESS`
export const ATTACHMENTS_SPA_FAILURE = `@modules/@SPA/ATTACHMENTS_SPA_FAILURE`
export const ATTACHMENTS_SPA_CLEAR = `@modules/@SPA/ATTACHMENTS_SPA_CLEAR`

export const insertSPAAttachmentsActions = {
  request: createAction(ATTACHMENTS_SPA_REQUEST)<IUpdateSPAStatusApiReq>(),
  success: createAction(ATTACHMENTS_SPA_SUCCESS)<IUpdateSPAStatusApiRes>(),
  failure: createAction(ATTACHMENTS_SPA_FAILURE)<Error>(),
  clear: createAction(ATTACHMENTS_SPA_CLEAR)()
}

export const ACCOUNT_SEARCH_REQUEST = `@modules/@SPA/ACCOUNT_SEARCH_REQUEST`
export const ACCOUNT_SEARCH_SUCCESS = `@modules/@SPA/ACCOUNT_SEARCH_SUCCESS`
export const ACCOUNT_SEARCH_FAILURE = `@modules/@SPA/ACCOUNT_SEARCH_FAILURE`
export const ACCOUNT_SEARCH_CLEAR = `@modules/@SPA/ACCOUNT_SEARCH_CLEAR`

export const accountSearchActions = {
  request: createAction(ACCOUNT_SEARCH_REQUEST)<IExistedAccountApiReq>(),
  success: createAction(ACCOUNT_SEARCH_SUCCESS)<ISearchResult<IAccount>>(),
  failure: createAction(ACCOUNT_SEARCH_FAILURE)<Error>(),
  clear: createAction(ACCOUNT_SEARCH_CLEAR)()
}

export const FETCH_SPA_ATTACHMENTS = `@modules/@SPA/FETCH_SPA_ATTACHMENTS`
export const FETCH_SPA_ATTACHMENTS_SUCCESS = `@modules/@SPA/FETCH_SPA_ATTACHMENTS_SUCCESS`
export const FETCH_SPA_ATTACHMENTS_FAILURE = `@modules/@SPA/FETCH_SPA_ATTACHMENTS_FAILURE`
export const FETCH_SPA_ATTACHMENTS_CLEAR = `@modules/@SPA/FETCH_SPA_ATTACHMENTS_CLEAR`

export const fetchSPAAttachmentsActions = {
  request: createAction(FETCH_SPA_ATTACHMENTS)<number>(),
  success: createAction(FETCH_SPA_ATTACHMENTS_SUCCESS)<string[]>(),
  failure: createAction(FETCH_SPA_ATTACHMENTS_FAILURE)<Error>(),
  clear: createAction(FETCH_SPA_ATTACHMENTS_CLEAR)<undefined>()
}

export const DOWNLOAD_SPA_DOC = `@modules/@products/DOWNLOAD_SPA_DOC`
export const DOWNLOAD_SPA_DOC_SUCCESS = `@modules/@products/DOWNLOAD_SPA_DOC_SUCCESS`
export const DOWNLOAD_SPA_DOC_FAILURE = `@modules/@products/DOWNLOAD_SPA_DOC_FAILURE`

export const downloadSPADocActions = {
  request: createAction(DOWNLOAD_SPA_DOC)<{
    attachmentsURL: string
    fileName: string
  }>(),
  success: createAction(DOWNLOAD_SPA_DOC_SUCCESS)(),
  failure: createAction(DOWNLOAD_SPA_DOC_FAILURE)<Error>()
}

export const DELETE_SPA_DOC = `@modules/@products/DELETE_SPA_DOC`
export const DELETE_SPA_DOC_SUCCESS = `@modules/@products/DELETE_SPA_DOC_SUCCESS`
export const DELETE_SPA_DOC_FAILURE = `@modules/@products/DELETE_SPA_DOC_FAILURE`
export const DELETE_SPA_DOC_CLEAR = `@modules/@products/DELETE_SPA_DOC_CLEAR`

export const deleteSPADocActions = {
  request: createAction(DELETE_SPA_DOC)<{ fileName: string }>(),
  success: createAction(DELETE_SPA_DOC_SUCCESS)(),
  failure: createAction(DELETE_SPA_DOC_FAILURE)<Error>(),
  clear: createAction(DELETE_SPA_DOC_CLEAR)<undefined>()
}

export const GENERATE_PDF_SPA = `@modules/@SPA/GENERATE_PDF_SPA`
export const GENERATE_PDF_SPA_SUCCESS = `@modules/@SPA/GENERATE_PDF_SPA_SUCCESS`
export const GENERATE_PDF_SPA_FAILURE = `@modules/@SPA/GENERATE_PDF_SPA_FAILURE`
export const GENERATE_PDF_SPA_CLEAR = `@modules/@SPA/GENERATE_PDF_SPA_CLEAR`

export const generatePDFSPAActions = {
  request: createAction(GENERATE_PDF_SPA)<number>(),
  success: createAction(GENERATE_PDF_SPA_SUCCESS)<IGeneratePDFSPAApiRes>(),
  failure: createAction(GENERATE_PDF_SPA_FAILURE)<Error>(),
  clear: createAction(GENERATE_PDF_SPA_CLEAR)<undefined>()
}
export const GENERATE_ACCOUNT_NUMBER = `@modules/@SPA/GENERATE_ACCOUNT_NUMBER`
export const GENERATE_ACCOUNT_NUMBER_SUCCESS = `@modules/@SPA/GENERATE_ACCOUNT_NUMBER_SUCCESS`
export const GENERATE_ACCOUNT_NUMBER_FAILURE = `@modules/@SPA/GENERATE_ACCOUNT_NUMBER_FAILURE`
export const GENERATE_ACCOUNT_NUMBER_CLEAR = `@modules/@SPA/GENERATE_ACCOUNT_NUMBER_CLEAR`

export const generateAccountNumberActions = {
  request: createAction(GENERATE_ACCOUNT_NUMBER)(),
  success: createAction(GENERATE_ACCOUNT_NUMBER_SUCCESS)<string>(),
  failure: createAction(GENERATE_ACCOUNT_NUMBER_FAILURE)<Error>(),
  clear: createAction(GENERATE_ACCOUNT_NUMBER_CLEAR)<undefined>()
}

export const CUSIP_SEQUENCE_NUMBER = `@modules/@SPA/CUSIP_SEQUENCE_NUMBER`
export const CUSIP_SEQUENCE_NUMBER_SUCCESS = `@modules/@SPA/CUSIP_SEQUENCE_NUMBER_SUCCESS`
export const CUSIP_SEQUENCE_NUMBER_FAILURE = `@modules/@SPA/CUSIP_SEQUENCE_NUMBER_FAILURE`
export const CUSIP_SEQUENCE_NUMBER_CLEAR = `@modules/@SPA/CUSIP_SEQUENCE_NUMBER_CLEAR`

export const fetchCusipSequenceNumberActions = {
  request: createAction(CUSIP_SEQUENCE_NUMBER)(),
  success: createAction(CUSIP_SEQUENCE_NUMBER_SUCCESS)<string>(),
  failure: createAction(CUSIP_SEQUENCE_NUMBER_FAILURE)<Error>(),
  clear: createAction(CUSIP_SEQUENCE_NUMBER_CLEAR)<undefined>()
}

export const ADVISOR_SECURITY_BY_CUSIP = `@modules/@SPA/ADVISOR_SECURITY_BY_CUSIP`
export const ADVISOR_SECURITY_BY_CUSIP_SUCCESS = `@modules/@SPA/ADVISOR_SECURITY_BY_CUSIP_SUCCESS`
export const ADVISOR_SECURITY_BY_CUSIP_FAILURE = `@modules/@SPA/ADVISOR_SECURITY_BY_CUSIP_FAILURE`
export const ADVISOR_SECURITY_BY_CUSIP_CLEAR = `@modules/@SPA/ADVISOR_SECURITY_BY_CUSIP_CLEAR`

export const fetchAdvisorSecurityByCusipActions = {
  request: createAction(ADVISOR_SECURITY_BY_CUSIP)<string>(),
  success: createAction(
    ADVISOR_SECURITY_BY_CUSIP_SUCCESS
  )<IGetAdvisorSecurityByCusipApiRes>(),
  failure: createAction(ADVISOR_SECURITY_BY_CUSIP_FAILURE)<Error>(),
  clear: createAction(ADVISOR_SECURITY_BY_CUSIP_CLEAR)<undefined>()
}

export const ADVISOR_ACCOUNT_BY_CLIENTID = `@modules/@SPA/ADVISOR_ACCOUNT_BY_CLIENTID`
export const ADVISOR_ACCOUNT_BY_CLIENTID_SUCCESS = `@modules/@SPA/ADVISOR_ACCOUNT_BY_CLIENTID_SUCCESS`
export const ADVISOR_ACCOUNT_BY_CLIENTID_FAILURE = `@modules/@SPA/ADVISOR_ACCOUNT_BY_CLIENTID_FAILURE`
export const ADVISOR_ACCOUNT_BY_CLIENTID_CLEAR = `@modules/@SPA/ADVISOR_ACCOUNT_BY_CLIENTID_CLEAR`

export const fetchAdvisorAccountsByClientIdActions = {
  request: createAction(ADVISOR_ACCOUNT_BY_CLIENTID)<string>(),
  success: createAction(
    ADVISOR_ACCOUNT_BY_CLIENTID_SUCCESS
  )<IGetAdvisorAccountsByClientIdApiRes>(),
  failure: createAction(ADVISOR_ACCOUNT_BY_CLIENTID_FAILURE)<Error>(),
  clear: createAction(ADVISOR_ACCOUNT_BY_CLIENTID_CLEAR)<undefined>()
}

export const CUSTODIAN_NAME_REQUEST = `@modules/@SPA/CUSTODIAN_NAME_REQUEST`
export const CUSTODIAN_NAME_SUCCESS = `@modules/@SPA/CUSTODIAN_NAME_SUCCESS`
export const CUSTODIAN_NAME_FAILURE = `@modules/@SPA/CUSTODIAN_NAME_FAILURE`
export const CUSTODIAN_NAME_CLEAR = `@modules/@SPA/CUSTODIAN_NAME_CLEAR`

export const custodianNameActions = {
  request: createAction(CUSTODIAN_NAME_REQUEST)(),
  success: createAction(CUSTODIAN_NAME_SUCCESS)<ICustodianName[]>(),
  failure: createAction(CUSTODIAN_NAME_FAILURE)<Error>(),
  clear: createAction(CUSTODIAN_NAME_CLEAR)()
}

export const VALIDATE_SPA_REQUEST = `@modules/@SPA/VALIDATE_SPA_REQUEST`
export const VALIDATE_SPA_SUCCESS = `@modules/@SPA/VALIDATE_SPA_SUCCESS`
export const VALIDATE_SPA_FAILURE = `@modules/@SPA/VALIDATE_SPA_FAILURE`
export const VALIDATE_SPA_CLEAR = `@modules/@SPA/VALIDATE_SPA_CLEAR`

export const validateSPAActions = {
  request: createAction(VALIDATE_SPA_REQUEST)<IInsertUpdateSPAApiReq>(),
  success: createAction(VALIDATE_SPA_SUCCESS)<IValidationMessage[]>(),
  failure: createAction(VALIDATE_SPA_FAILURE)<Error>(),
  clear: createAction(VALIDATE_SPA_CLEAR)()
}

export const ERROR_SPA_REQUEST = `@modules/@SPA/ERROR_SPA_REQUEST`
export const ERROR_SPA_SUCCESS = `@modules/@SPA/ERROR_SPA_SUCCESS`
export const ERROR_SPA_FAILURE = `@modules/@SPA/ERROR_SPA_FAILURE`
export const ERROR_SPA_CLEAR = `@modules/@SPA/ERROR_SPA_CLEAR`

export const errorSPAActions = {
  request: createAction(ERROR_SPA_REQUEST)(),
  success: createAction(ERROR_SPA_SUCCESS)<ISPAError[]>(),
  failure: createAction(ERROR_SPA_FAILURE)<Error>(),
  clear: createAction(ERROR_SPA_CLEAR)()
}

export const EXPORT_SPA = `@modules/@SPA/EXPORT_SPA`
export const EXPORT_SPA_SUCCESS = `@modules/@SPA/EXPORT_SPA_SUCCESS`
export const EXPORT_SPA_FAILURE = `@modules/@SPA/EXPORT_SPA_FAILURE`

export const exportSPAToExcelActions = {
  request: createAction(EXPORT_SPA)<ISPASummaryExportReq>(),
  success: createAction(EXPORT_SPA_SUCCESS)(),
  failure: createAction(EXPORT_SPA_FAILURE)<Error>()
}

export const EXPORT_SPAERROR = `@modules/@SPA/EXPORT_SPAERROR`
export const EXPORT_SPAERROR_SUCCESS = `@modules/@SPA/EXPORT_SPAERROR_SUCCESS`
export const EXPORT_SPAERROR_FAILURE = `@modules/@SPA/EXPORT_SPAERROR_FAILURE`

export const ERROR_SEARCH_REQUEST = `@modules/@SPA/ERROR_SEARCH_REQUEST`
export const ERROR_SEARCH_SUCCESS = `@modules/@SPA/ERROR_SEARCH_SUCCESS`
export const ERROR_SEARCH_FAILURE = `@modules/@SPA/ERROR_SEARCH_FAILURE`
export const ERROR_SEARCH_CLEAR = `@modules/@SPA/ERROR_SEARCH_CLEAR`

export const ERROR_FILTER_REQUEST = `@modules/@SPA/ERROR_FILTER_REQUEST`
export const ERROR_FILTER_SUCCESS = `@modules/@SPA/ERROR_FILTER_SUCCESS`
export const ERROR_FILTER_FAILURE = `@modules/@SPA/ERROR_FILTER_FAILURE`
export const ERROR_FILTER_CLEAR = `@modules/@SPA/ERROR_FILTER_CLEAR`

export const errorSearchActions = {
  request: createAction(ERROR_SEARCH_REQUEST)<any>(),
  success: createAction(ERROR_SEARCH_SUCCESS)<ISearchResult<ISPAError>>(),
  failure: createAction(ERROR_SEARCH_FAILURE)<Error>(),
  clear: createAction(ERROR_SEARCH_CLEAR)()
}

export const exportSPAErrorToExcelActions = {
  request: createAction(EXPORT_SPAERROR)<ISPAErrorExportReq>(),
  success: createAction(EXPORT_SPAERROR_SUCCESS)(),
  failure: createAction(EXPORT_SPAERROR_FAILURE)<Error>()
}

export const FUND_ADMINS = `@modules/@SPA/FUND_ADMINS`
export const FUND_ADMINS_SUCCESS = `@modules/@SPA/FUND_ADMINS_SUCCESS`
export const FUND_ADMINS_FAILURE = `@modules/@SPA/FUND_ADMINS_FAILURE`
export const FUND_ADMINS_CLEAR = `@modules/@SPA/FUND_ADMINS_CLEAR`

export const fetchFundAdminsActions = {
  request: createAction(FUND_ADMINS)(),
  success: createAction(FUND_ADMINS_SUCCESS)<IFundAdmin[]>(),
  failure: createAction(FUND_ADMINS_FAILURE)<Error>(),
  clear: createAction(FUND_ADMINS_CLEAR)<undefined>()
}

export const FETCH_SPA_CRMAccounts = `@modules/@SPA/FETCH_SPA_CRMAccounts`
export const FETCH_SPA_CRMAccounts_SUCCESS = `@modules/@SPA/FETCH_SPA_CRMAccounts_SUCCESS`
export const FETCH_SPA_CRMAccounts_FAILURE = `@modules/@SPA/FETCH_SPA_CRMAccounts_FAILURE`
export const FETCH_SPA_CRMAccounts_CLEAR = `@modules/@SPA/FETCH_SPA_CRMAccounts_CLEAR`

export const fetchCRMAccountsActions = {
  request: createAction(FETCH_SPA_CRMAccounts)(),
  success: createAction(FETCH_SPA_CRMAccounts_SUCCESS)<IAccountRequests[]>(),
  failure: createAction(FETCH_SPA_CRMAccounts_FAILURE)<Error>(),
  clear: createAction(FETCH_SPA_CRMAccounts_CLEAR)<undefined>()
}

export const EXPORT_CRMAccounts = `@modules/@SPA/EXPORT_CRMAccounts`
export const EXPORT_CRMAccounts_SUCCESS = `@modules/@SPA/EXPORT_CRMAccounts_SUCCESS`
export const EXPORT_CRMAccounts_FAILURE = `@modules/@SPA/EXPORT_CRMAccounts_FAILURE`

export const exportCRMAccountsToExcelActions = {
  request: createAction(EXPORT_CRMAccounts)<ICRMAccountExportReq>(),
  success: createAction(EXPORT_CRMAccounts_SUCCESS)(),
  failure: createAction(EXPORT_CRMAccounts_FAILURE)<Error>()
}

export type SPAFetchActionType = ActionType<typeof fetchExistingSPAActions>
export type SPAInsertUpdateActionType = ActionType<
  typeof insertUpdateSPAActions
>
export type clientSearchActionType = ActionType<typeof clientSearchActions>
export type advisorSearchActionType = ActionType<typeof advisorSearchActions>
export type securitySearchActionType = ActionType<typeof securitySearchActions>

export type updateSPAStatusActionType = ActionType<
  typeof updateSPAStatusActions
>
export type insertSPAAttachmentsActionType = ActionType<
  typeof insertSPAAttachmentsActions
>

export type accountSearchActionType = ActionType<typeof accountSearchActions>

export type fetchSPAAttachmentsActionType = ActionType<
  typeof fetchSPAAttachmentsActions
>
export type downloadSPADocActionType = ActionType<typeof downloadSPADocActions>
export type deleteSPADocActionType = ActionType<typeof deleteSPADocActions>
export type generatePDFSPAActionType = ActionType<typeof generatePDFSPAActions>
export type generateAccountNumberActionType = ActionType<
  typeof generateAccountNumberActions
>
export type fetchCusipSequenceNumberActionsType = ActionType<
  typeof fetchCusipSequenceNumberActions
>
export type fetchAdvisorSecurityByCusipActionsType = ActionType<
  typeof fetchAdvisorSecurityByCusipActions
>
export type fetchAdvisorAccountsByClientIdActionsType = ActionType<
  typeof fetchAdvisorAccountsByClientIdActions
>
export type custodianNameActionType = ActionType<typeof custodianNameActions>
export type validateSPAActionType = ActionType<typeof validateSPAActions>
export type errorSPAActionType = ActionType<typeof errorSPAActions>
export type errorSearchActionsType = ActionType<typeof errorSearchActions>
export type exportSPAToExcelActionType = ActionType<
  typeof exportSPAToExcelActions
>
export type exportSPAErrorToExcelActionType = ActionType<
  typeof exportSPAErrorToExcelActions
>
export type fetchFundAdminsActionsType = ActionType<
  typeof fetchFundAdminsActions
>
export type fetchCRMAccountsActionsType = ActionType<
  typeof fetchCRMAccountsActions
>
export type exportCRMAccountsToExcelActionType = ActionType<
  typeof exportCRMAccountsToExcelActions
>
export type SPAActionsTypes =
  | SPAInsertUpdateActionType
  | clientSearchActionType
  | advisorSearchActionType
  | securitySearchActionType
  | SPAFetchActionType
  | updateSPAStatusActionType
  | insertSPAAttachmentsActionType
  | accountSearchActionType
  | fetchSPAAttachmentsActionType
  | downloadSPADocActionType
  | deleteSPADocActionType
  | generatePDFSPAActionType
  | generateAccountNumberActionType
  | custodianNameActionType
  | validateSPAActionType
  | errorSPAActionType
  | exportSPAToExcelActionType
  | exportSPAErrorToExcelActionType
  | fetchCusipSequenceNumberActionsType
  | fetchAdvisorSecurityByCusipActionsType
  | fetchAdvisorAccountsByClientIdActionsType
  | errorSearchActionsType
  | fetchFundAdminsActionsType
  | fetchCRMAccountsActionsType
  | exportCRMAccountsToExcelActionType
