import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { IDragDropFile } from '../components/DragDrop'
import {
  IFetchOcrDocsReq,
  IOcrAttachmentGetResponce,
  IOCRUploadApi
} from '../store/types'

const fetchOcrUploadService = (
  options: IApiOptions,
  request: IFetchOcrDocsReq
) => {
  const url = `${options.apiRoot}/cdm/Performance/getUploadedAttachments`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IOcrAttachmentGetResponce | undefined>(url, request, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const uploadOcrFileService = (
  req: IOCRUploadApi,
  options: IApiOptions,
  onUploadProgress?: (
    progressEvent: ProgressEvent<EventTarget>,
    file: IDragDropFile
  ) => void
) => {
  const url = `${options.apiRoot}/cdm/Performance/UploadAttachments`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  const data = new FormData()
  data.append('attachments', req.docFile?.file ?? '')
  data.append('attachmentType', req.attachmentType)
  return axios
    .post(url, data, {
      headers,
      cancelToken: options.cancelToken,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            onUploadProgress(progressEvent, req.docFile)
          }
        : undefined
    })
    .then((res) => res.data)
}

const viewOcrFileService = (
  fileUrlPath: string,
  downloadFileName: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Performance/downloadAttachment?fileUrlPath=${fileUrlPath}&downloadFileName=${downloadFileName}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const deleteOcrFileService = (
  fileUrlPath: string,
  attachmentType: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/Performance/deleteAttachment?fileUrlPath=${fileUrlPath}&attachmentType=${attachmentType}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .delete<string>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export {
  uploadOcrFileService,
  fetchOcrUploadService,
  viewOcrFileService,
  deleteOcrFileService
}
