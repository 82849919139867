import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import {
  IGetRAMRequest,
  IUpdateRAMStatusApiRes,
  IRAMForm,
  IRAMFormRes,
  IRAMGetResponce,
  IUpdateRAMStatusApiReq,
  IRaiseCashGetResponce,
  IUpdateRaiseCashStatusApiReq
} from './types'

const fetchExistingRAMService = (req: IGetRAMRequest, options: IApiOptions) => {
  const url =
    Number(req?.draftID) > 0
      ? `${options.apiRoot}/cdm/RAM/GetAccountMappingRequest?draftID=${req.draftID}`
      : Number(req?.requestID) > 0
      ? `${options.apiRoot}/cdm/RAM/GetAccountMappingRequest?requestID=${req.requestID}`
      : `${options.apiRoot}/cdm/RAM/GetAccountMappingRequest`

  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IRAMGetResponce>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const insertUpdateRAMService = (req: IRAMForm, options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/RAM/SetAccountMappingRequest`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IRAMFormRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const updateRAMStatusService = (
  req: IUpdateRAMStatusApiReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/RAM/UpdateAccountMappingRequestStatus`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .put<IUpdateRAMStatusApiRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchRaiseCashService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/RAM/GetUmaAccountRaiseCash`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IRaiseCashGetResponce>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const updateRaiseCashStatusService = (
  req: IUpdateRaiseCashStatusApiReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/RAM/UpdateUmaAccountRaiseCashStatus`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .put<IUpdateRAMStatusApiRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export {
  fetchExistingRAMService,
  insertUpdateRAMService,
  updateRAMStatusService,
  fetchRaiseCashService,
  updateRaiseCashStatusService
}
