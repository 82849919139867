import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../types'
import { ocrReducer } from './store/reducer'
import { OCRsagas } from './store/sagas'

const OcrContainer = lazy(() => import('./OcrContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/spa/*',
    private: true,
    component: OcrContainer
  },
  name: 'OCR'
}

export default moduleDefinition

export const ocrModuleReducer = combineReducers({
  dasboard: ocrReducer
})

export const ocrModuleSagas = [...OCRsagas]
