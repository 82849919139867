import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'

enum ColumnIdsEnum {
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  CUSIP = 'CUSIP',
  OWNERSHIP = 'OWNERSHIP',
  LOT_RECEIVED_DATE = 'LOT_RECEIVED_DATE',
  OPEN_LOT_SETTLEMENT_DATE = 'OPEN_LOT_SETTLEMENT_DATE',
  LOT_QUANTITY = 'LOT_QUANTITY',
  TAX_LOT_COST_PRICE = 'TAX_LOT_COST_PRICE',
  LOT_MARKET_VALUE = 'LOT_MARKET_VALUE',
  CLOSING_MARKET_PRICE = 'CLOSING_MARKET_PRICE',
  GAIN_LOSS_UNREALIZED = 'GAIN_LOSS_UNREALIZED',
  LOT_ID = 'LOT_ID',
  ADJUSTED_COST = 'ADJUSTED_COST',
  UNADJUSTED_COST = 'UNADJUSTED_COST',
  COST_AVAILABILE = 'COST_AVAILABILE',
  LastUpdatedAt = 'LastUpdatedAt'
}

export const TaxLotColumnIds = { ...ColumnIdsEnum }
export type TaxLotColumnIds = typeof TaxLotColumnIds

export const TaxLotColumnDefinitions: Record<string, IColumnDefinition> = {
  [TaxLotColumnIds.ACCOUNT_NUMBER]: {
    id: TaxLotColumnIds.ACCOUNT_NUMBER,
    name: 'Account Number',
    type: 'string',
    width: 90,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'ACCOUNT_NUMBER'
  },
  [TaxLotColumnIds.CUSIP]: {
    id: TaxLotColumnIds.CUSIP,
    name: 'Cusip',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'CUSIP'
  },
  [TaxLotColumnIds.OWNERSHIP]: {
    id: TaxLotColumnIds.OWNERSHIP,
    name: 'Ownership',
    type: 'string',
    width: 90,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'OWNERSHIP'
  },
  [TaxLotColumnIds.LOT_RECEIVED_DATE]: {
    id: TaxLotColumnIds.LOT_RECEIVED_DATE,
    name: 'Lot Received Date',
    type: 'date',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'LOT_RECEIVED_DATE'
  },
  [TaxLotColumnIds.OPEN_LOT_SETTLEMENT_DATE]: {
    id: TaxLotColumnIds.OPEN_LOT_SETTLEMENT_DATE,
    name: 'Lot Settlement Date',
    type: 'date',
    width: 110,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'OPEN_LOT_SETTLEMENT_DATE'
  },
  [TaxLotColumnIds.LOT_QUANTITY]: {
    id: TaxLotColumnIds.LOT_QUANTITY,
    name: 'Lot Quantity',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'LOT_QUANTITY',
    className: 'USDColumn'
  },
  [TaxLotColumnIds.TAX_LOT_COST_PRICE]: {
    id: TaxLotColumnIds.TAX_LOT_COST_PRICE,
    name: 'Tax Lot Cost Price',
    type: 'number',
    width: 140,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'TAX_LOT_COST_PRICE',
    className: 'USDColumn'
  },
  [TaxLotColumnIds.LOT_MARKET_VALUE]: {
    id: TaxLotColumnIds.LOT_MARKET_VALUE,
    name: 'Lot Market Value (COB)',
    type: 'number',
    width: 140,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'LOT_MARKET_VALUE',
    className: 'USDColumn'
  },
  [TaxLotColumnIds.CLOSING_MARKET_PRICE]: {
    id: TaxLotColumnIds.CLOSING_MARKET_PRICE,
    name: 'Closing Market Price',
    type: 'number',
    width: 140,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'CLOSING_MARKET_PRICE',
    className: 'USDColumn'
  },
  [TaxLotColumnIds.GAIN_LOSS_UNREALIZED]: {
    id: TaxLotColumnIds.GAIN_LOSS_UNREALIZED,
    name: 'Gain Loss Unrealized (COB)',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'GAIN_LOSS_UNREALIZED',
    className: 'USDColumn'
  },
  [TaxLotColumnIds.ADJUSTED_COST]: {
    id: TaxLotColumnIds.ADJUSTED_COST,
    name: 'Adjusted Cost',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'ADJUSTED_COST',
    className: 'USDColumn'
  },
  [TaxLotColumnIds.UNADJUSTED_COST]: {
    id: TaxLotColumnIds.UNADJUSTED_COST,
    name: 'Un-Adjusted Cost',
    type: 'number',
    width: 130,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'UNADJUSTED_COST',
    className: 'USDColumn'
  },
  [TaxLotColumnIds.COST_AVAILABILE]: {
    id: TaxLotColumnIds.COST_AVAILABILE,
    name: 'Cost Available',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'COST_AVAILABILE'
  },
  [TaxLotColumnIds.LOT_ID]: {
    id: TaxLotColumnIds.LOT_ID,
    name: 'Lot ID',
    type: 'string',
    width: 220,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'LOT_ID'
  },
  [TaxLotColumnIds.LastUpdatedAt]: {
    id: TaxLotColumnIds.LastUpdatedAt,
    name: 'Updated Date',
    type: 'date',
    width: 90,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'LastUpdatedAt'
  }
}
