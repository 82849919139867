import { MessageBarType } from '@fluentui/react'
import { createReducer } from 'typesafe-actions'
import {
  deleteOcrFileActions,
  fetchOcrFileActions,
  OcrActionTypes,
  viewOcrFileActions
} from './actions'
import { IOcrAttachmentGetResponce } from './types'

export interface IOCRState {
  fetchOcrUploadState?: {
    inProgress: boolean
    errorCode?: string
    msg?: string
    messageType?: MessageBarType
    data?: IOcrAttachmentGetResponce
  }
  viewOcrFile?: {
    isLoading: boolean
    error?: Error
    messageType?: MessageBarType
  }
  deleteOcrFile?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
  }
}

const initialState: IOCRState = {
  fetchOcrUploadState: {
    inProgress: false,
    errorCode: undefined,
    msg: undefined,
    messageType: undefined,
    data: undefined
  }
}

export const ocrReducer = createReducer<IOCRState, OcrActionTypes>(initialState)
  .handleAction(fetchOcrFileActions.request, (state) => ({
    ...state,
    fetchOcrUploadState: {
      inProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchOcrFileActions.success, (state, action) => ({
    ...state,
    fetchOcrUploadState: {
      inProgress: false,
      error: undefined,
      data: action.payload,
      messageType: MessageBarType.success
    }
  }))
  .handleAction(fetchOcrFileActions.failure, (state, action) => ({
    ...state,
    fetchOcrUploadState: {
      inProgress: false,
      error: action.payload,
      data: undefined,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(fetchOcrFileActions.clear, (state) => ({
    ...state,
    fetchOcrUploadState: undefined
  }))

  .handleAction(viewOcrFileActions.request, (state) => ({
    ...state,
    viewStatement: {
      ...state.viewOcrFile,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(viewOcrFileActions.success, (state) => ({
    ...state,
    viewStatement: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(viewOcrFileActions.failure, (state, action) => ({
    ...state,
    viewStatement: {
      ...state.viewOcrFile,
      inProgress: false,
      error: action.payload
    }
  }))

  .handleAction(deleteOcrFileActions.request, (state) => ({
    ...state,
    deleteOcrFile: {
      ...state.deleteOcrFile,
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(deleteOcrFileActions.success, (state) => ({
    ...state,
    deleteOcrFile: {
      inProgress: false,
      error: undefined,
      messageType: MessageBarType.success,
      msg: 'Successfully deleted attachment'
    }
  }))
  .handleAction(deleteOcrFileActions.failure, (state, action) => ({
    ...state,
    deleteOcrFile: {
      ...state.deleteOcrFile,
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(deleteOcrFileActions.clear, (state) => ({
    ...state,
    deleteOcrFile: undefined
  }))
